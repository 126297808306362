.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "Rubrik";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "Underrubrik";
}

.quill, .ql-content, .ql-editor {
  min-height: 80vh !important;
}

.ql-toolbar {
  position: sticky;
  top: 0px;
  background-color: #FFFFFF;
  z-index: 999;
}

.ql-editor > h1, .ql-editor > h2, .ql-editor > p, .ql-editor > ol, .ql-editor > ul {
  margin-bottom: 0.5em;
}

.ql-snow .ql-editor h1 {
  font-size: 24px;
}

.ql-snow .ql-editor h2 {
  font-size: 20px;
}

.ql-snow .ql-editor p, .ql-snow .ql-editor ol, .ql-snow .ql-editor li {
  font-size: 14px;
}

[contenteditable] ~ grammarly-extension,
input ~ grammarly-extension,
textarea ~ grammarly-extension,
grammarly-desktop-integration,
grammarly-popups {
  display: none !important;
}