@import "~antd/dist/antd.css";
.ant-picker-now-btn {
  visibility: hidden;
}
.ant-picker-now-btn:before {
  visibility: visible;
  content: "Nu";
  display: block;
}
.teacher-open-essay > p {
  margin-bottom: 0.5em;
}