.youtube-carousel > .slick-prev:before, .slick-next:before {
  color: #22333b;
  font-size: 30px;
}

.youtube-carousel > .slick-next {
  right: -15px;
}

.youtube-carousel > .slick-back {
  left: 15px;
}

.youtube-carousel > .slick-dots li button:before {
  font-size: 8px;
}

.youtube-carousel > .slick-dots li.slick-active button:before {
  color: #22333b;
}